<template>
  <div class="message-page">
    <div
      class="message--section flex flex-col relative border border-gray-500 m-4"
    >
      <div
        class="message--header border-b border-gray-200 flex justify-between items-center p-4"
      >
        <h3 class="text-rose text-3xl">{{ $t('message') }}</h3>
        <BackFa :text="$t('back')" />
      </div>

      <div :class="{ rtl: isLtr }">
        <div class="messages--content p-10 overflow-y-auto">
          <div class="flex mr-5 w-full">
            <div>
              <div
                class="bg-profile-1 overflow-hidden w-20 h-20 rounded-full border-blue-300"
              ></div>
              <div class="text-center text-gray-500">
                <small class="block">{{ $t('day') }}</small>
                <small>{{ $t('time') }}</small>
              </div>
            </div>
            <div class="text-right">
              <div class="relative p-3 rounded-lg mr-5 mt-2 bg-blue-200 w-4/5">
                <p class="opacity-90 text-base">
                  {{ $t('text_message_user') }}
                </p>
                <i
                  class="text-blue-200 absolute text-4xl -right-2 top-1 fas fa-caret-right"
                ></i>
              </div>
            </div>
          </div>

          <div class="flex w-full">
            <div class="w-full text-right ml-5 items-center flex justify-end">
              <div class="relative p-3 rounded-lg bg-gray-200 w-4/5">
                <p class="opacity-90 text-base">
                  {{ $t('text_message1_doctor') }}
                </p>
                <i
                  class="text-gray-200 absolute text-4xl -left-2 top-1 fas fa-caret-left"
                ></i>
              </div>
            </div>
            <div>
              <div
                class="bg-profile-2 w-20 h-20 rounded-full border-gray-200"
              ></div>
              <div class="text-center text-gray-500">
                <small class="block">{{ $t('day') }}</small>
                <small>{{ $t('time') }}</small>
              </div>
            </div>
          </div>
          <div class="flex w-full mt-3">
            <div class="w-full text-right ml-5 items-center flex justify-end">
              <div class="relative p-3 rounded-lg bg-gray-200 w-4/5">
                <p class="opacity-90 text-base">
                  {{ $t('text_message2_doctor') }}
                </p>
                <i
                  class="text-gray-200 absolute text-4xl -left-2 top-1 fas fa-caret-left"
                ></i>
              </div>
            </div>
            <div>
              <div
                class="bg-profile-2 w-20 h-20 rounded-full border-gray-200"
              ></div>
              <div class="text-center text-gray-500">
                <small class="block">{{ $t('day') }}</small>
                <small>{{ $t('time') }}</small>
              </div>
            </div>
          </div>
        </div>
        <div
          class="messages--footer w-2/3 py-2 px-4 z-10 m-auto mb-2 flex items-center bg-gray-400 rounded-2xl"
        >
          <div class="flex text-white text-3xl">
            <i class="fal fa-microphone cursor-pointer"></i>
            <i class="fal fa-smile mx-10 cursor-pointer"></i>
            <i class="fal fa-camera-alt cursor-pointer"></i>
          </div>
          <hr class="mx-5 h-8 w-0.5 bg-white" />
          <div class="relative w-3/4">
            <input
              v-model="inputText"
              class="w-full placeholder-gray-100 bg-gray-400 outline-none text-white text-base font-bold"
              type="text"
              :placeholder="$t('text_input_message')"
            />
          </div>
          <i
            class="text-white text-2xl fas fa-long-arrow-alt-up cursor-pointer"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackFa from '../components/back/BackFa.vue'
export default {
  name: 'Messages',
  components: { BackFa },
}
</script>

<style>
.message--section {
  height: calc(100% - theme('spacing.8'));
}
.messages--content {
  max-height: calc(100% - theme('spacing.32'));
}
.bg-profile-1 {
  border-width: 6px;
  background-image: url('../assets/images/men.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 30%;
}
.bg-profile-2 {
  border-width: 6px;
  background-image: url('../assets/images/men2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 30%;
  background-position-y: 30%;
}
</style>
